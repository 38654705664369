
























































































































































































































































































































































































































































import { defineComponent, ref, computed, reactive, onMounted } from '@vue/composition-api'
import moment from 'moment'
import { sumBy, orderBy, debounce } from 'lodash'
import { api, framebus } from 'plugins'
import { OrderDetail, Assign as TAssign, MultiLevelProduct } from 'typings'
import { useQuery, useShowError } from 'hooks'
import {
  endpoints,
  groupMultiLevel,
  groupTwoLevel,
  urlPath,
  mappingStock,
  query,
  showError,
  toCamelCase,
  frameBusEvent,
  convertToWidthCharacter,
  ASSIGN_DES,
  ORDER_DES
} from 'utils'

import {
  LoaderComponent,
  MultiLevelList,
  StepButton,
  MultiLevelItem,
  AddButton,
  SingleDetailItem,
  SettingViewDialog,
  DateRange,
  ConfirmDelete,
  PreviewInvoiceDialog
} from 'components'
import BottomSheet from './BottomSheet.vue'
import AutoAssignDialog from './AutoAssignDialog.vue'

interface SelectedLevel {
  rootIndex: number | null
  firstIndex: number | null
  secondIndex: number | null
}

const Assign = defineComponent({
  components: {
    LoaderComponent,
    MultiLevelList,
    BottomSheet,
    StepButton,
    MultiLevelItem,
    AutoAssignDialog,
    AddButton,
    SingleDetailItem,
    SettingViewDialog,
    DateRange,
    ConfirmDelete,
    PreviewInvoiceDialog
  },
  setup(props, { root }) {
    const { $toast, $route, $router, $store } = root
    const isFromCalendar = $route.query.fromCalendar
    const assignProduct = ref<any[]>([])
    const assignProductSearched = ref<any[]>([])
    const assignLoading = ref(false)
    const orderDetails = ref<OrderDetail[]>([])
    const orderDetailSearched = ref<OrderDetail[]>([])
    const orderLoading = ref(false)
    const previewInvoiceDialogValue = ref(false)
    const treeviewLevel = ref(
      Number(
        $store.state.common.settingMaster.find((e: any) => e.key === 'treeview_level')?.value
      ) || 4
    )

    const {
      show_by: showByValue,
      search_by: searchByValue,
      search_info: searchInfoValue,
      filter_confirm: filterConfirmValue
    } = $route.query

    // check in store, if data available then get auction_date, packing_range from store
    const auctionDate = ref(
      $store.state.common.latestSelectedDates.auctionDate
        ? $store.state.common.latestSelectedDates.auctionDate
        : $route.query.date || moment(new Date()).format('YYYY-MM-DD')
    )
    const startDateValue = ref(
      $store.state.common.latestSelectedDates.packingDateRange
        ? $store.state.common.latestSelectedDates.packingDateRange[0]
        : $route.query.start_date || moment(new Date()).format('YYYY-MM-DD')
    )
    const endDateValue = ref(
      $store.state.common.latestSelectedDates.packingDateRange
        ? $store.state.common.latestSelectedDates.packingDateRange[1]
        : $route.query.end_date || moment(new Date()).format('YYYY-MM-DD')
    )
    // if go to page from calendar page, get date from url
    if (isFromCalendar) {
      auctionDate.value = $route.query.auctionDate
      startDateValue.value = $route.query.start_date
      endDateValue.value = $route.query.end_date
    }

    const nextAuctionDate = ref('')
    const date = ref('')
    const preDate = ref('')
    const searchInfo = ref((searchInfoValue as string) || '')

    const getNextAuctionDate = async () => {
      try {
        const data = await api.get(`${endpoints.NOSALE_DATE}/next_auction_date`)
        nextAuctionDate.value = data.data.date
        date.value = nextAuctionDate.value as string
        preDate.value = date.value
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const selectItems = [
      { name: root.$t('assign.order_by_product'), key: 'ORDER BY PRODUCT' },
      { name: root.$t('assign.order_by_customer'), key: 'ORDER BY CUSTOMER' },
      { name: root.$t('assign.assign_by_product'), key: 'ASSIGN BY PRODUCT' },
      { name: root.$t('assign.assign_by_customer'), key: 'ASSIGN BY CUSTOMER' },
      { name: root.$t('history'), key: 'ASSIGN BY HISTORY' }
    ]

    const selectIsConfirmed = [
      { name: root.$t('common.all'), key: 'ALL' },
      // { name: root.$t('assign.confirm.confirmed'), key: 'CONFIRMED' },
      { name: root.$t('assign.confirm.unconfirmed'), key: 'UNCONFIRMED' }
    ]

    const searchItems = [
      { name: 'All', key: 'ALL' },
      { name: root.$t('assign.not_assign_yet'), key: 'NOT_ASSIGN_YET' },
      { name: root.$t('assign.assigned'), key: 'ASSIGNED' }
    ]

    const modal = ref(false)
    const startDate = ref(startDateValue.value || moment().format('YYYY-MM-DD'))
    const endDate = ref(endDateValue.value || moment().day(7).format('YYYY-MM-DD'))

    const dateRange = ref([startDateValue, endDateValue])
    dateRange.value = [startDate.value, endDate.value]

    const startDateModal = ref(false)
    const endDateModal = ref(false)

    const showBy = ref(showByValue || 'ASSIGN BY PRODUCT')
    const filterConfirm = ref(filterConfirmValue || 'ALL')
    const searchBy = ref(searchByValue || 'ALL')
    const allowSearchLoading = ref(false)
    const showActionSheet = ref(false)
    const selectedAssignId = ref(null)
    const showDelete = ref(false)
    const selectedLevel = reactive<SelectedLevel>({
      rootIndex: null,
      firstIndex: null,
      secondIndex: null
    })

    const orderPanelState = computed(() => $store.state.assign.orderPanelState)

    const customerPanelState = computed(() => $store.state.assign.customerPanelState)
    const assignByProductPanelState = computed(() => $store.state.assign.assignByProductPanelState)
    const assignByCustomerPanelState = computed(
      () => $store.state.assign.assignByCustomerPanelState
    )

    const isShowSetting = ref(false)
    const isShowByBox = ref(false)
    const isShowTotal = ref(true)
    const isShowRemaining = ref(false)
    const getSettingDefault = async (): Promise<void> => {
      try {
        const response = await api.get(`${endpoints.COMMON}get_setting`)
        const settingMember = response.data.setting.assign
        isShowByBox.value = settingMember?.is_show_by_box || false
        isShowTotal.value = settingMember ? settingMember.is_show_total : true
        isShowRemaining.value = settingMember?.is_show_remaining || false
      } catch (e) {
        showError(e, $toast, root.$t('setting.error.get_default_setting_fail') as string)
      }
    }
    const getSettingLevel = async (): Promise<void> => {
      treeviewLevel.value =
        Number(
          $store.state.common.settingMaster.find((e: any) => e.key === 'treeview_level')?.value
        ) || 4
    }
    const updateSetting = (
      isShowTotalValue: boolean,
      isShowByBoxValue: boolean,
      isShowRemainingValue: boolean
    ) => {
      isShowByBox.value = isShowByBoxValue
      isShowTotal.value = isShowTotalValue
      isShowRemaining.value = isShowRemainingValue
      isShowSetting.value = false
      // update state
      $store.commit('setShowValueAssign', {
        isShowByBox: isShowByBoxValue,
        isShowTotal: isShowTotalValue,
        isShowRemaining: isShowRemainingValue
      })
    }

    const updatePanelState = (mutation: string, panelState: Record<string, unknown>) => {
      $store.commit(mutation, panelState)
    }

    const queryStr = computed(() => {
      return query.buildQuery({
        start_date: startDate.value,
        end_date: endDate.value
      })
    })

    const assignUrl = computed(() => {
      return `${endpoints.ASSIGNMENTS}?auction_date=${date.value}&order_by_customer=${
        showBy.value === 'ASSIGN BY CUSTOMER'
      }`
    })
    const orderDetailUrl = computed(() => {
      return `${endpoints.ORDER_DETAILS}?auction_date=${date.value}&order_by_customer=${
        showBy.value === 'ORDER BY CUSTOMER'
      }`
    })
    const packingUrl = computed(() => {
      return `${endpoints.PACKING_RESULTS}get_packing_result_by_range?${queryStr.value}`
    })
    const harvestUrl = computed(() => {
      return `${endpoints.HARVEST_RESULT}?${queryStr.value}`
    })

    const endPoint = ref('')

    const replaceUrl = (): void => {
      const initQuery = showBy.value === 'ORDER BY PRODUCT' ? { search_by: searchBy.value } : {}
      $router
        .replace({
          query: {
            auction_date: date.value,
            show_by: showBy.value,
            search_by: searchBy.value,
            start_date: startDate.value,
            end_date: endDate.value,
            search_info: searchInfo.value,
            filter_confirm: filterConfirm.value,
            called: 'true',
            ...initQuery
          }
        })
        .catch((e) => {
          console.log(e)
        })
      const latestSelectedDates = {
        auctionDate: date.value.toString(),
        packingDateRange: [startDate.value, endDate.value]
      }
      // eslint-disable-next-line no-restricted-globals
      // parent.postMessage(latestSelectedDates, '*')
      framebus.emit(frameBusEvent.DATE, latestSelectedDates)
    }

    const filterConfirmed = () => {
      if (filterConfirm.value === 'CONFIRMED') {
        assignProductSearched.value = assignProductSearched.value.filter(
          (assign: any) => assign.isConfirmed
        )
      }
      if (filterConfirm.value === 'UNCONFIRMED') {
        assignProductSearched.value = assignProductSearched.value.filter(
          (assign: any) => !assign.isConfirmed
        )
      }
    }

    const filterAssignOrder = (value: string) => {
      searchInfo.value = value
      const text = convertToWidthCharacter(value, 'full')
      if (text === '') {
        assignProductSearched.value = assignProduct.value
        orderDetailSearched.value = orderDetails.value
      } else if (showBy.value === 'ORDER BY PRODUCT' || showBy.value === 'ORDER BY CUSTOMER') {
        orderDetailSearched.value = orderDetails.value.filter((e: any) => {
          const { item, variety, size, quality, order } = e
          return (
            item.searchStr?.includes(text) ||
            variety.searchStr?.includes(text) ||
            size.searchStr?.includes(text) ||
            quality.searchStr?.includes(text) ||
            order.customer.searchStr?.includes(text)
          )
        })
      } else if (
        showBy.value === 'ASSIGN BY PRODUCT' ||
        showBy.value === 'ASSIGN BY CUSTOMER' ||
        showBy.value === 'ASSIGN BY HISTORY'
      ) {
        assignProductSearched.value = assignProduct.value.filter((e: any) => {
          const { item, variety, size, quality, customer } = e
          return (
            item.searchStr?.includes(text) ||
            variety.searchStr?.includes(text) ||
            size.searchStr?.includes(text) ||
            quality.searchStr?.includes(text) ||
            customer.searchStr?.includes(text)
          )
        })
      }
      filterConfirmed()
    }

    const getAssignProduct = async () => {
      assignLoading.value = true
      try {
        const { data } = await api.get(assignUrl.value)
        assignProduct.value = toCamelCase(data)
        assignProductSearched.value = toCamelCase(data)
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      } finally {
        assignLoading.value = false
      }
      filterAssignOrder(searchInfo.value)
    }

    const orderEndPoint = ref('')

    const getOrderDetails = async () => {
      orderLoading.value = true
      try {
        const { data } = await api.get(orderDetailUrl.value)
        orderDetails.value = toCamelCase(data).map((order: any) => ({
          ...order,
          orderedSpecialStems: order.boxes ? 0 : order.stems
        }))
        orderDetailSearched.value = JSON.parse(JSON.stringify(orderDetails.value))
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      } finally {
        orderLoading.value = false
      }
      filterAssignOrder(searchInfo.value)
    }

    const packingEndPoint = ref(packingUrl.value)
    const { data: packingResult, error: packingError } = useQuery(() => packingEndPoint.value)
    useShowError($toast, packingError)

    const harvestEndPoint = ref(harvestUrl.value)
    const { data: harvestResult, error: harvestError } = useQuery(() => harvestEndPoint.value)
    useShowError($toast, harvestError)

    const assignDataByProduct = computed(() => {
      if (!assignProductSearched.value) return []
      const orderData = orderDetailSearched.value || []
      const packingResultData = packingResult.value || []
      const harvestResultData = harvestResult.value || []

      const assignDataWithOrder = assignProductSearched.value.map((assign: TAssign) => {
        const order = orderData.find(
          (orderDetail: OrderDetail) => orderDetail.id === assign.orderDetail
        )

        return {
          ...assign,
          orderStems: order?.stems,
          orderBoxes: order?.boxes,
          orderedSpecialStems: order?.boxes ? 0 : order?.stems,
          assignStems: assign.stems,
          assignBoxes: assign.boxes,
          orderId: order?.id
        }
      })

      let groupData = []
      if (treeviewLevel.value === 2) {
        groupData = groupTwoLevel(
          assignDataWithOrder,
          ['orderStems', 'orderBoxes', 'assignStems', 'assignBoxes', 'orderedSpecialStems'],
          ['itemName']
        )
      } else {
        groupData = groupMultiLevel(assignDataWithOrder, [
          'orderStems',
          'orderBoxes',
          'assignStems',
          'assignBoxes',
          'orderedSpecialStems'
        ])
      }
      return mappingStock(
        groupData,
        {
          packingResults: packingResultData,
          harvestResults: harvestResultData,
          rawDestination: assignDataWithOrder
        },
        treeviewLevel.value === 2,
        ASSIGN_DES
      )
    })

    const handleSearchInput = debounce((value) => {
      filterAssignOrder(value)
      replaceUrl()
    }, 500)

    const assignDataByCustomer = computed(() => {
      if (!assignProductSearched.value) return []
      const orderData = orderDetailSearched.value || []
      const packingResultData = packingResult.value || []
      const harvestResultData = harvestResult.value || []
      const assignDataWithOrder = assignProductSearched.value.map((assign: TAssign) => {
        const qualitySizeName =
          `${assign.quality?.name || ''}${assign.size?.name || ''}` || root.$t('order.no_size')
        const customerName = `[${assign.customer.code || ''}] ${assign.customer.shortName}`
        const order = orderData.find(
          (orderDetail: OrderDetail) => orderDetail.id === assign.orderDetail
        )

        return {
          qualitySizeName,
          customerName,
          ...assign,
          orderStems: order?.stems,
          orderBoxes: order?.boxes,
          assignStems: assign.stems,
          assignBoxes: assign.boxes,
          orderedSpecialStems: order?.boxes ? 0 : order?.stems,
          orderId: order?.id
        }
      })
      let groupData = []
      if (treeviewLevel.value === 2) {
        groupData = groupTwoLevel(
          assignDataWithOrder,
          ['orderStems', 'assignStems', 'orderBoxes', 'assignBoxes', 'orderedSpecialStems'],
          ['customerName']
        )
      } else {
        groupData = groupMultiLevel(
          assignDataWithOrder,
          ['orderStems', 'assignStems', 'orderBoxes', 'assignBoxes', 'orderedSpecialStems'],
          ['customerName', 'itemName', 'varietyName', 'qualitySizeName']
        )
      }
      return mappingStock(
        groupData,
        {
          packingResults: packingResultData,
          harvestResults: harvestResultData,
          rawDestination: assignDataWithOrder
        },
        treeviewLevel.value === 2,
        ASSIGN_DES
      )
    })

    const historyAssignData = computed(() => {
      if (!assignProductSearched.value) return []
      const orderData = orderDetailSearched.value || []

      const historyAssignDataWithOrderStems = assignProductSearched.value.map((assign: TAssign) => {
        const order = orderData.find(
          (orderDetail: OrderDetail) => orderDetail.id === assign.orderDetail
        )

        return {
          ...assign,
          orderStems: order?.stems,
          orderBoxes: order?.boxes,
          assignStems: assign.stems,
          assignBoxes: assign.boxes
        }
      })

      return orderBy(historyAssignDataWithOrderStems, 'id', 'desc')
    })

    const orderByCustomer = computed(() => {
      if (!orderDetailSearched.value) return []
      const assignProductData = assignProductSearched.value || []

      const detailFilter = orderDetailSearched.value.filter((detail: OrderDetail) => {
        if (searchBy.value === 'ASSIGNED') {
          return detail.isAssigned === true
        }

        if (searchBy.value === 'NOT_ASSIGN_YET') {
          return detail.isAssigned === false
        }

        return true
      })

      const groupByCustomer = detailFilter.map((detail: OrderDetail) => {
        const qualitySizeName =
          `${detail.quality?.name || ''}${detail.size?.name || ''}` || root.$t('order.no_size')
        const customerName = `[${detail.order.customer.code || ''}] ${
          detail.order.customer.shortName
        }`

        const assignStems = sumBy(
          assignProductData.filter((assign: TAssign) => assign.orderDetail === detail.id),
          'stems'
        )

        const assignBoxes = sumBy(
          assignProductData.filter((assign: TAssign) => assign.orderDetail === detail.id),
          'boxes'
        )

        return {
          customerName,
          qualitySizeName,
          assignStems,
          assignBoxes,
          orderStems: detail.stems,
          orderBoxes: detail.boxes,
          ...detail
        }
      })
      let groupData = []

      if (treeviewLevel.value === 2) {
        groupData = groupTwoLevel(
          groupByCustomer,
          ['orderStems', 'orderBoxes', 'assignStems', 'assignBoxes', 'orderedSpecialStems'],
          ['customerName']
        )
      } else {
        groupData = groupMultiLevel(
          groupByCustomer,
          ['orderStems', 'orderBoxes', 'assignStems', 'assignBoxes', 'orderedSpecialStems'],
          ['customerName', 'itemName', 'varietyName']
        )
      }
      // find isAssigned in second level
      return groupData
    })

    const orderDetailData = computed(() => {
      if (!orderDetailSearched.value) return []
      const assignProductData = assignProductSearched.value || []
      const packingResultData = packingResult.value || []
      const harvestResultData = harvestResult.value || []

      const detailFilter = orderDetailSearched.value.filter((detail: OrderDetail) => {
        if (searchBy.value === 'ASSIGNED') {
          return detail.isAssigned === true
        }

        if (searchBy.value === 'NOT_ASSIGN_YET') {
          return detail.isAssigned === false
        }

        return true
      })

      const details = detailFilter.map((detail: OrderDetail) => {
        const assignStems = sumBy(
          assignProductData.filter((assign: TAssign) => assign.orderDetail === detail.id),
          'stems'
        )

        const assignBoxes = sumBy(
          assignProductData.filter((assign: TAssign) => assign.orderDetail === detail.id),
          'boxes'
        )

        return {
          assignStems,
          assignBoxes,
          orderStems: detail.stems,
          orderBoxes: detail.boxes,
          ...detail
        }
      })

      let groupData = []
      if (treeviewLevel.value === 2) {
        groupData = groupTwoLevel(
          details,
          ['orderStems', 'orderBoxes', 'assignStems', 'assignBoxes', 'orderedSpecialStems'],
          ['itemName']
        )
      } else {
        groupData = groupMultiLevel(details, [
          'orderStems',
          'orderBoxes',
          'assignStems',
          'assignBoxes',
          'orderedSpecialStems'
        ])
      }

      return mappingStock(
        groupData,
        {
          packingResults: packingResultData,
          harvestResults: harvestResultData,
          rawDestination: details
        },
        treeviewLevel.value === 2,
        ORDER_DES
      )
    })

    const assignSearching = computed(() => {
      if (assignProductSearched.value && allowSearchLoading.value) {
        return true
      }
      return false
    })

    const orderSearching = computed(() => {
      if (orderDetailSearched.value && allowSearchLoading.value) {
        return true
      }
      return false
    })

    const searchResult = (): void => {
      replaceUrl()
      packingEndPoint.value = packingUrl.value
      harvestEndPoint.value = harvestUrl.value
    }

    const handleFilterConfirm = () => {
      replaceUrl()
      filterAssignOrder(searchInfo.value)
    }

    const reload = async (enableScrollToPreviousPosition: boolean): Promise<void> => {
      // get position before reload
      const positionY = window.scrollY
      await getSettingLevel()
      // mutateAssign()
      await getAssignProduct()
      // mutateOrderDetail()
      await getOrderDetails()

      // scroll to previous position
      if (enableScrollToPreviousPosition) {
        window.scrollTo(0, positionY)
      }
    }

    const search = (): void => {
      if (date.value !== preDate.value) {
        updatePanelState('setOrderPanelState', {})
        updatePanelState('setCustomerPanelState', {})
        updatePanelState('setAssignByProductPanelState', {})
        updatePanelState('setAssignByCustomerPanelState', {})
      }
      replaceUrl()
      endPoint.value = assignUrl.value
      orderEndPoint.value = orderDetailUrl.value
      reload(false)
      // allowSearchLoading.value = true
    }

    const createOrEditAssign = (
      assignId: number | null = null,
      orderDetailId: number | null = null,
      isDuplicated: boolean | null = false
    ): void => {
      const initQuery = orderDetailId
        ? {
            order_detail_id: orderDetailId.toString()
          }
        : {}

      $router
        .push({
          name: urlPath.ASSIGN_FORM.name,
          params: {
            assignId: assignId ? assignId.toString() : 'create',
            isDuplicated: isDuplicated ? '1' : '0'
          },
          query: { ...initQuery, auction_date: date.value, called: 'true' }
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const openActionSheet = (
      rootIndex: number,
      firstIndex: number | null = null,
      secondIndex: number | null = null
    ) => {
      selectedLevel.rootIndex = rootIndex
      selectedLevel.firstIndex = firstIndex
      selectedLevel.secondIndex = secondIndex

      showActionSheet.value = true
    }

    // const expandAll = () => {
    //   const { data, action, keys, state } = currentDataState.value
    //   const argument = {
    //     data,
    //     keys,
    //     state,
    //     selected: selectedLevel
    //   }

    //   updatePanelState(action, getExpandPanelState(argument))
    // }

    // const collapseAll = () => {
    //   const { data, action, keys, state } = currentDataState.value
    //   const argument = {
    //     data,
    //     keys,
    //     state,
    //     selected: selectedLevel
    //   }

    //   updatePanelState(action, getCollapsePanelState(argument))
    // }

    const batchAssignStateByProduct = computed(() => {
      const { rootIndex, firstIndex, secondIndex } = selectedLevel
      const groupByItem = orderDetailData.value[rootIndex as number] as MultiLevelProduct
      const { itemName } = groupByItem

      const varietyName = firstIndex !== null ? groupByItem.children[firstIndex].varietyName : null
      const qualitySizeName =
        secondIndex !== null
          ? groupByItem.children[firstIndex as number].children[secondIndex].qualitySizeName
          : null

      const details = orderDetailSearched.value.filter((detail: OrderDetail) => {
        const item = detail.item.name
        const variety = String(detail.variety.name)
        const qualitySize =
          `${detail.quality?.name || ''}${detail.size?.name || ''}` || root.$t('order.no_size')

        if (secondIndex !== null) {
          return item === itemName && variety === varietyName && qualitySize === qualitySizeName
        }

        if (firstIndex !== null) {
          return item === itemName && variety === varietyName
        }

        return item === itemName
      })
      return {
        item: details[0].item,
        variety: firstIndex !== null ? details[0].variety : null,
        size: secondIndex !== null && details[0].size?.id ? details[0].size : null,
        quality: secondIndex !== null && details[0].quality?.id ? details[0].quality : null,
        orderDetailIds: details.map((detail: OrderDetail) => detail.id)
      }
    })

    const handleBatchAssign = (): void => {
      $store.commit('setBatchAssignState', {
        ...batchAssignStateByProduct.value
      })
      createOrEditAssign()
    }

    const changeShowBy = () => {
      handleSearchInput(searchInfo.value)
      replaceUrl()
      reload(false)
    }

    const handleBottomSheetAction = (event: string) => {
      switch (event) {
        case 'DELETE':
          showDelete.value = true
          break
        case 'EDIT':
          createOrEditAssign(selectedAssignId.value)
          break
        case 'BATCH_ASSIGN':
          handleBatchAssign()
          break
        default:
      }

      showActionSheet.value = false
    }

    const setDate = (start: string, end: string) => {
      startDate.value = start
      endDate.value = end
    }

    const deleteAssignDetail = async (): Promise<boolean> => {
      try {
        await api.delete(`${endpoints.ASSIGNMENTS}${selectedAssignId.value}`)
        $toast.success(root.$t('common.msg.delete_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure'))
      }
      return false
    }

    const confirmDelete = async (action: string): Promise<void> => {
      let success = false
      if (action === 'delete') {
        success = await deleteAssignDetail()
      }
      if (success) {
        reload(true)
      }
      showDelete.value = false
      selectedAssignId.value = null
    }
    const currentIdLevel = ref<number>(0)
    const onOpenPreviewInvoiceDialog = (idLevel: number) => {
      previewInvoiceDialogValue.value = true
      currentIdLevel.value = idLevel
    }
    onMounted(async () => {
      if (
        !(
          $store.state.common.latestSelectedDates.auctionDate &&
          $store.state.common.latestSelectedDates.packingDateRange
        ) &&
        !isFromCalendar
      )
        await getNextAuctionDate()
      else date.value = auctionDate.value

      await getAssignProduct()
      await getOrderDetails()
      await getSettingLevel()
      const showValueState = $store.state.assign.showValue
      if (Object.keys(showValueState).length) {
        isShowByBox.value = showValueState.isShowByBox
        isShowRemaining.value = showValueState.isShowRemaining
        isShowTotal.value = showValueState.isShowTotal
      } else {
        getSettingDefault()
      }
    })

    return {
      dateRange,
      modal,
      date,
      auctionDate,
      startDateModal,
      endDateModal,
      startDate,
      endDate,
      selectItems,
      showBy,
      selectedAssignId,
      historyAssignData,
      search,
      searchResult,
      replaceUrl,
      assignLoading,
      assignDataByProduct,
      assignSearching,
      orderDetailData,
      orderByCustomer,
      orderLoading,
      orderSearching,
      createOrEditAssign,
      showActionSheet,
      handleBottomSheetAction,
      orderPanelState,
      customerPanelState,
      assignByProductPanelState,
      assignByCustomerPanelState,
      updatePanelState,
      openActionSheet,
      selectedLevel,
      reload,
      searchItems,
      searchBy,
      assignDataByCustomer,
      isShowByBox,
      isShowTotal,
      isShowRemaining,
      isShowSetting,
      updateSetting,
      setDate,
      treeviewLevel,
      showDelete,
      confirmDelete,
      previewInvoiceDialogValue,
      onOpenPreviewInvoiceDialog,
      currentIdLevel,
      searchInfo,
      handleSearchInput,
      changeShowBy,
      selectIsConfirmed,
      filterConfirm,
      handleFilterConfirm
    }
  }
})

export default Assign
